.checkout-cart-index {
    .totals-wrapper {
        table {
          .totals.discount {
            background: none;
            .title {
                background: none;
                padding: 0;
                 &::before {
                        background: none;
                        content: none;
                    }
               &.-enabled{
                &::after {
                 transform: rotate(180deg);
               }  
                }
            }
          }
          .total-rules {
            background-color: $gray-200;
            display: none;
            .mark, .amount {
                padding: 0 10px;
            }
          }       
         }
    }
}